<template>
    <v-card flat v-if="!editStatus">
        <v-toolbar flat color="info">
            <v-toolbar-title>
                {{getTheme.name}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn icon color="secondary" @click="setEditStatus()">
                    <v-icon>fas fa-wrench</v-icon>
                </v-btn>
                <v-btn icon color="black" v-if="getUser.status" @click="editTheme()">
                    <v-icon>fas fa-edit</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="subtitle-1">
            <v-row>
                <v-col cols="12"><b>DESCRIPTION:</b>
                <br>
                {{getTheme.description}}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12"><b>HEROES:</b></v-col>
                <v-col cols="6" md="2" v-for="hero in getTheme.heroes" :key="hero">
                    <li>
                        {{hero}}
                    </li>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-5">
                <v-col cols="12"><b>SPECIFIC DETAILS:</b></v-col>
                <v-col cols="12">
                    {{getTheme.notes}}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <b>THEME ACTIVITY:</b>
                    <br>
                    <v-row v-for="note in getNotes" :key="note.id" no-gutters align="center" class="mytable">
                        <v-col cols="12" md="2" class="pl-1">{{note.date != '..' ? note.date : "--/--/----"}}</v-col>
                        <v-col cols="2" >VS.</v-col>
                        <v-col cols="8" md="6">
                            <!-- <router-link :to="getLink(note)" class="secondary--text"> -->
                                {{ note.themeA == getTheme.name ? note.themeB : note.themeA}}
                            <!-- </router-link> -->
                        </v-col>
                        <v-col cols="2" class="text-center">{{ note.winner == getTheme.name ? "Won" : "Lost"}}</v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <!-- SUBMITTING AN ISSUE FOR THIS THEME -->
    <v-card v-else>
        <v-toolbar flat color="info">
            <v-toolbar-title>
                Submitting an issue for: {{getTheme.name}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn icon color="secondary" @click="setEditStatus()">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="subtitle-1">
            <v-row>
                <v-col cols="12">
                    <v-textarea color="secondary" v-model="request" outlined label="What change would you like to make?"></v-textarea>
                </v-col>
                <v-col cols="12">
                    <v-btn outlined color="secondary" @click="createIssue()">
                        <v-icon left>fas fa-share-square</v-icon>
                        Do the Thing!
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data(){
        return {
            editStatus          : false,
            request             : ""
        }
    },
    methods: {
        createIssue(){
            let issueData = {
                active      : true,
                created     : new Date().toString(),
                request     : this.request,
                resolved    : "",
                resolvedBy  : "",
                theme       : this.getTheme.name,
                themeId     : this.getTheme.id
            }
            this.$store.dispatch('createIssue', issueData)
        },
        getLink(note){
            console.log(note)
            // let themeName       = this.getTheme.name
            // let otherTheme      = note.themeA == themeName ? note.themeB : note.themeA
            // let otherThemeData  = this.getThemes.find(theme => theme.name == otherTheme)
            // return `/theme/${otherThemeData.id}`
        },
        editTheme(){
            this.$router.push({ name: 'edit', params: { themeData: this.getTheme } })
        },
        setEditStatus(){
            this.editStatus = !this.editStatus
        }
    },
    computed: {
        getIssues(){
            return this.$store.getters.getIssues.filter(issue => issue.theme == this.getTheme.name)
        },
        getNotes(){
            let allNotes    = this.$store.getters.getNotes
            return allNotes.filter(note => note.themeA == this.getTheme.name || note.themeB == this.getTheme.name)
        },
        getUser(){
            return this.$store.getters.getUser
        },
        getTheme(){
            let id          = this.$route.path.split('/')[2]
            let allThemes   = this.$store.getters.getThemes
            return allThemes.filter(theme => theme.id == id)[0]
        },
        getThemes(){
            return this.$store.getters.getThemes
        }
    }
}
</script>

<style scoped>
.mytable:nth-child(odd){
    background: #d7dae5;
}
</style>